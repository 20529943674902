import api from '@/api/api';
import Saving from "@/classes/Saving";
import Enrollment from "@/classes/Enrollment";
import Swal from "sweetalert2";
import { getTokenDecoden } from "@/helpers/tokenauth.js";
import { formatCurrencyWithDecimal, unformatCurrency } from '@/helpers/filters.js';

const host = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA;

async function get_client_active_benefits(){
    
    const auth = getTokenDecoden();
    const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/client/benefits`;
    const apiUrl = `${host}${resource}`;

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const params = {
        token_auth: sessionStorage.getItem("login"),
        client_id: auth.obj.person.client_id
    };
    
    try {
        const _response = await api.httpGet(apiUrl, config, params);
        return _response.data.response.benefits;
        
    } catch (ex) {
        Swal.fire({
            title: "Aviso",
            html: ex.response.data.response.message,
            icon: "info",
            confirmButtonText: "OK",
            confirmButtonColor: '#FEB72B'
        });
        return false;
    }
}

function show_card_benefit(listSimpleCard,node_key,list_benefits){
    const benefit_select = find_product_select(list_benefits,node_key);
    let card_benefit_select = find_position_card_benefit(listSimpleCard,node_key);
    /* card_benefit_select.status = benefit_select.collection_method == "nomina" ? true : false; */
}

function find_position_card_benefit(listSimpleCard,node_key){
    return listSimpleCard.find(element => element.node_key == node_key );
}

function find_product_select(list_benefits,node_key){
    for(const element of list_benefits ){
        if(Object.keys(element) == node_key){
            return element[node_key];
        }
    }
}

async function  get_information_section_benefit(listSimpleCard,node_key, values){

    let card_benefit_select = find_position_card_benefit(listSimpleCard, node_key);
    switch(node_key){
        case "foncabsatemporalsaving":
            try {
                card_benefit_select.status = true;  
                let current_balance = values.filter(element => element.node_key == node_key )[0];
                let current_balance_cents = current_balance.current_balance_cents;
                let savings_yield_cents = current_balance.savings_yield_cents;

                let sumTotalSaving = sumTotalSavings(current_balance_cents, savings_yield_cents);

                card_benefit_select.mainText = `<p class='main-text text-2xl font-semibold'>${ formatCurrencyWithDecimal(sumTotalSaving/100) }</p>`;
            } catch (err) {
                console.log(err) 
                card_benefit_select.value = formatCurrencyWithDecimal(0);
            };
        break;

        case "foncabsatemporalenrollment":
            try {
                let amount_cents = values.filter(element => element.node_key == node_key )[0]; 
                card_benefit_select.status = true;         
                card_benefit_select.description = "Pendiente";
                card_benefit_select.mainText = `<p class='main-text text-2xl font-semibold'>${ formatCurrencyWithDecimal(amount_cents.current_balance_cents/100) }</p>`
            } catch (err) { 
                console.log(err)
                card_benefit_select.description = "N/A";
                card_benefit_select.mainText = `<p class='main-text text-2xl font-semibold'>$ 0.00</p>`
                card_benefit_select.value = formatCurrencyWithDecimal(0);
            };
        break;
    }

    return listSimpleCard;
}

async function  get_information_benefit(node_key, values){
    console.log("🚀 ~ file: clientBenefits.js:100 ~ get_information_benefit ~ values", values)
    let benefit = values.filter(benefit => benefit.node_key == node_key);
    return (benefit.length>0)?benefit[0]: [];
}

function sumTotalSavings(current_balance_cents, savings_yield_cents){
    let current_balance = current_balance_cents;
    let total = current_balance + savings_yield_cents
    return total;
}

async function get_benefits_by_client_id(clientId){
    const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/clients/get_active_benefits_summary`;
    const apiUrl = `${host}${resource}`;

    const config = {
        headers: {
            "Content-Type": "application/json",
        }
    };

    const params = {
        token_auth: sessionStorage.getItem("login"),
        client_id: `${clientId}`
    };
    
    try {
        const _response = await api.httpGet(apiUrl, config, params);
        return _response.data.response;
    } catch (err) {
        return err;
    }
}

async function create_array_structure(benefits){
    benefits = benefits.result;
        
    let arr = [];
    for (let index = 0; index < Object.keys(benefits).length; index++) {
        
      const nodeKey = (Object.keys(benefits))[index];
      let element = benefits[nodeKey];
      element['node_key'] = nodeKey;
      element['status'] = false;
      arr.push(element);
    }

    return arr;
}

export { 
    get_client_active_benefits,
    show_card_benefit,
    get_information_section_benefit,
    get_benefits_by_client_id,
    create_array_structure, 
    get_information_benefit,
    sumTotalSavings
}