import api from '@/api/api'
const host = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA
import Swal from "sweetalert2";
export default class Saving {
    
    currentBalance = async (tokenAuth, clientId) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/savings/current_balance`;
        const apiUrl = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: tokenAuth,
            client_id: clientId
        };
        
        try {
            const _response = await api.httpGet(apiUrl, config, params);
            return _response.data.response;
        } catch (err) {
            Swal.fire({
                title: "Aviso",
                html: err.response.data.response.message,
                icon: "info",
                confirmButtonText: "OK",
                confirmButtonColor: '#FEB72B'
            });
            return false;
        }
    }

    basicSummary = async (tokenAuth, clientId) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/savings/basic_summary`;
        const apiUrl = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: tokenAuth,
            client_id: clientId
        };
        
        try {
            return await api.httpGet(apiUrl, config, params); 
        } catch (error) {
            throw error.response.data.mensaje;
        }
    }

    getBenefits = async (tokenAuth, clientId) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/client/benefits`;
        const apiUrl = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: tokenAuth,
            client_id: clientId
        };
        
        try {
            return await api.httpGet(apiUrl, config, params); 
        } catch (error) {
            throw error.response.data.mensaje;
        }
    }

    getPaymentHistory = async (tokenAuth, clientId) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/savings/get_payment_history`;
        const apiUrl = `${host}${resource}`;

        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };

        const params = {
            token_auth: tokenAuth,
            client_id: clientId
        };
        
        try {
            return await api.httpGet(apiUrl, config, params); 
        } catch (error) {
            throw error.response.data.mensaje;
        }
    }

    getBenefitsByClientId = async (clientId) => {
        const resource = `/${process.env.VUE_APP_BUSINESS_FONCABSA}/clients/get_active_benefits_summary`;
        const apiUrl = `${host}${resource}`;
    
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };
    
        const params = {
            token_auth: sessionStorage.getItem("login"),
            client_id: `${clientId}`
        };
        
        try {
            const _response = await api.httpGet(apiUrl, config, params);
            return _response.data.response;
        } catch (err) {
            return err;
        }
    }
}